<template>

  <div 
  role="region" 
  aria-label="Notifications (F8)" 
  tabindex="-1"
  v-if="text" 
  >
  <span 
  aria-hidden="true" 
  tabindex="0" 
  style="position: fixed; border: 0px; width: 1px; display: inline-block; height: 1px; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; overflow-wrap: normal;"></span>
  <ol 
  tabindex="-1" 
  class="fixed bottom-[20px] z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]">
    <li 
    role="status" 
    aria-live="off" 
    tabindex="0" 
    class="nba text-white group pointer-events-auto relative flex w-full items-center justify-between space-x-2 overflow-hidden rounded-md p-4 pr-6 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full border bg-background text-foreground" 
    :class="{
      'bg-green': status === 'success',
      'bg-yellow': status === 'warning',
      'bg-red': status === 'error',
      'bg-blue': status === 'info'
    }"
    data-swipe-direction="right" 
    >
      <div class="grid gap-1">
        <div class="text-sm font-semibold [&+div]:text-xs">
          {{ text }}
        </div>
        <button 
        type="button" 
        class="absolute right-1 top-1 rounded-md p-1 text-foreground/50 opacity-0 transition-opacity hover:text-foreground focus:outline-none focus:ring-0 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50">
          <svg 
          width="15" 
          height="15" 
          viewBox="0 0 15 15" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg" 
          class="h-4 w-4">
            <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" 
            fill="currentColor"></path>
          </svg>
        </button>
      </div>
    </li>
  </ol>
  <span 
  aria-hidden="true" 
  tabindex="0" 
  style="position: fixed; border: 0px; width: 1px; display: inline-block; height: 1px; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; overflow-wrap: normal;"></span>
  </div>

</template>

<script setup>
const messageStore = useMessageStore()
const { text, status } = storeToRefs(messageStore)
</script>